import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  breakpoint: number = 2;
  constructor() { }

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 1120) ? 1 : 2;
}

  onResize(event: any) {
    this.breakpoint = (event.target.innerWidth <= 1120) ? 1 : 2;
  }

}
