<div class="row py-5 text-center">
    <div class="col-12 col-xl-6 pt-3">
        <h2>
            About Orcwood YAK
        </h2>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/gVea8D7WwhM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>    
        <p style="width:560px" class="pt-3 mx-auto">
            My sister and I started this channel to explore making videos on Youtube. The point is to have fun but not spend too much time making videos.
        </p>
        <p style="width:560px" class="mx-auto">
            I think that being able to speak well for the camera and engage with audiences around the world will continue to be a valuable skill and this channel gives us the opportunity to practice some of those skills.
        </p>
    </div>
    <div class="col-12 col-xl-6 pt-3">
        <h2>
            Top video
        </h2>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/DlC_wAG1JEA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>    
        <p style="width:560px" class="pt-3 mx-auto">
            Jenny started a new thing with hidden puzzles in each video ... beware.
        </p>
    </div>
</div>
            
            